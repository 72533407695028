import { createContext, useContext, useEffect } from 'react'
import { useCookies } from 'react-cookie'

type ThemeContextType = [string, (theme: string) => void]

const initialState: ThemeContextType = ['light', (_theme: string) => undefined]

const Context = createContext(initialState as ThemeContextType)

type Props = {
  children: React.ReactNode
}

const ThemeProvider = ({ children }: Props) => {
  const [cookies, setCookies, removeCookie] = useCookies(['theme'])

  const setTheme = (theme: string) => {
    removeCookie('theme') // DO NOT DELETE - This removes old cookies that lack the path option (and break the theme switcher)
    setCookies('theme', theme, { path: '/' })
  }

  useEffect(() => {
    if (cookies.theme === undefined) {
      const isOsDarkMode = typeof window !== 'undefined' && window.matchMedia('(prefers-color-scheme: dark)').matches
      setTheme(isOsDarkMode ? 'dark' : 'light')
    }
  }, [])

  useEffect(() => {
    document.querySelector('html')?.setAttribute('data-bs-theme', cookies.theme)
  }, [cookies.theme])

  return <Context.Provider value={[cookies.theme, setTheme]}>{children}</Context.Provider>
}

export const useTheme = (): ThemeContextType => useContext(Context)

export default ThemeProvider
