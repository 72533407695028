import { createMigrate, createTransform } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { ClipboardStateType, initialClipboardState } from './clipboard/initialState'

const ClipboardTransform = createTransform(
  (inboundState: ClipboardStateType, _key) => ({
    ...initialClipboardState,
    cards: inboundState.cards,
    commanders: inboundState.commanders,
  }),
  (outboundState, _key) => outboundState,
  { whitelist: ['clipboard'] },
)

const migrations = {
  0: (state: any): any => {
    const { cards, commander } = state.clipboard

    const newCards: Record<string, any> = {}
    Object.values(cards as Record<string, any>).forEach(({ url }) => {
      newCards[url] = null
    })
    const newCommander =
      commander === null
        ? undefined
        : typeof commander === 'object'
          ? commander.url
          : commander.length === 0
            ? null
            : commander

    return {
      clipboard: {
        ...state.clipboard,
        cards: newCards,
        commander: newCommander,
      },
    }
  },
  1: (state: any): any => {
    const { commander, ...rest } = state.clipboard
    return {
      clipboard: {
        ...rest,
        commanders: commander === null ? [] : [commander],
      },
    }
  },
  2: (state: any): any => {
    const { loadedCards, ...rest } = state.clipboard
    if (loadedCards === undefined) {
      return {
        clipboard: {
          ...rest,
          loadedCards: {},
        },
      }
    }
  },
}

const createNoopStorage = () => ({
  getItem(_key: any) {
    return Promise.resolve(null)
  },
  removeItem(_key: any) {
    return Promise.resolve()
  },
  setItem(_key: any, value: any) {
    return Promise.resolve(value)
  },
})
export const persistConfig = {
  blacklist: ['user'],
  key: 'root',
  migrate: createMigrate(migrations, { debug: false }),
  storage: typeof window === 'undefined' ? createNoopStorage() : (storage as any),
  transforms: [ClipboardTransform],
  version: 2,
  whitelist: ['clipboard'],
}
