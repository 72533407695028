import classNames from 'classnames'
import Alert from 'react-bootstrap/Alert'

import { mailtoBugReport } from '@/utils/helpers'

type Props = {
  hideStack?: boolean
  stack?: string
}

const ErrorAlert = ({ hideStack, stack }: Props) => (
  <Alert className='m-4' variant='danger'>
    <h4>An error occurred</h4>
    <p>Something went wrong and our team has been automatically notified.</p>
    <p className={classNames({ 'mb-0': hideStack })}>
      You can email us at{' '}
      <a href={mailtoBugReport(stack)} target='_blank'>
        bugreport@edhrec.com
      </a>
      .
    </p>
    {stack && !hideStack && <code>{stack}</code>}
  </Alert>
)

export default ErrorAlert
