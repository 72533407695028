import { PriceMapType } from '@/types/card'

export const initialPriceSources = ['cardkingdom', 'tcgplayer', 'scg'] as Array<keyof PriceMapType>

export type PatreonObjectType = {
  currently_entitled_amount_cents: number
  full_name: string
  next_charge_date: string
  patron_status: string
}

export type UserStateType = {
  alternateArt?: Record<string, string[]>
  displayName: string
  email: string
  isAdmin?: boolean
  isStaff?: boolean
  namesUnderCards?: boolean
  patreon?: PatreonObjectType
  priceSources: Array<keyof PriceMapType>
  region?: string
  saltLevel?: number
  uid?: string | null // null if Firebase auth is not signed in
  version: number
}

export const getUserInitialState = (): UserStateType => ({
  alternateArt: undefined,
  displayName: '',
  email: '',
  isAdmin: false,
  isStaff: false,
  namesUnderCards: false,
  patreon: undefined,
  priceSources: initialPriceSources,
  region: undefined,
  saltLevel: undefined,
  uid: undefined,
  version: 3,
})
