import { useCallback, useRef, useState } from 'react'
import { AsyncTypeahead as BSAsyncTypeahead } from 'react-bootstrap-typeahead'

import typeaheadService from '@/services/typeahead.service'
import { AsyncTypeaheadEntryType } from '@/types/typeahead'

import styles from './AsyncTypeahead.module.scss'

type Props = {
  className?: string
  clearOnChange?: boolean
  onChange: (selected: AsyncTypeaheadEntryType[]) => void
  placeholder: string
}

const AsyncTypeahead = ({ className, clearOnChange, onChange, placeholder }: Props) => {
  const ref = useRef<BSAsyncTypeahead<AsyncTypeaheadEntryType>>(null)
  const abortControllerRef = useRef<AbortController>()
  const [isLoading, setIsLoading] = useState(false)
  const [options, setOptions] = useState<AsyncTypeaheadEntryType[]>([])

  const handleSearch = useCallback(async (query: string) => {
    setIsLoading(true)

    // Cancel the previous fetch request
    if (abortControllerRef.current) {
      abortControllerRef.current.abort()
    }

    // Create a new AbortController instance
    const abortController = new AbortController()
    abortControllerRef.current = abortController

    const cleanedQuery = query
      .normalize('NFD')
      .toLowerCase()
      .replace(/[^a-z0-9]/g, '')
    try {
      const res = await typeaheadService.getFromQuery(cleanedQuery, abortController.signal)
      setOptions(await res.json())
    } catch (e: any) {
      if (e.name === 'AbortError') return
    } finally {
      setIsLoading(false)
    }
  }, [])

  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true

  return (
    <BSAsyncTypeahead<AsyncTypeaheadEntryType>
      className={className}
      filterBy={filterBy}
      id='async-typeahead'
      inputProps={{
        autoCapitalize: 'off',
        autoCorrect: 'off',
        spellCheck: false,
      }}
      isLoading={isLoading}
      minLength={3}
      onChange={(e) => {
        if (e.length > 0) {
          ref.current?.blur()
        }
        if (clearOnChange) {
          ref.current?.clear()
        }
        onChange(e)
      }}
      onSearch={handleSearch}
      options={options as any}
      placeholder={placeholder}
      ref={ref}
      renderMenuItemChildren={(option) => {
        const srcPaths = option.image ? [option.image.slice(0, 1), option.image.slice(1, 2), option.image] : ''
        const src =
          option.image && Array.isArray(srcPaths)
            ? `https://cards.scryfall.io/art_crop/front/${srcPaths.join('/')}`
            : ''
        return (
          <div className='align-items-center d-flex justify-content-begin gap-2'>
            {option.image ? (
              <span className={styles.left}>
                <div
                  className={styles.image}
                  style={{
                    backgroundImage: `url(${src})`,
                  }}
                />
              </span>
            ) : option.icon ? (
              <span className={styles.left}>
                <img alt={option.label} className={styles.icon} src={option.icon} />
              </span>
            ) : (
              <span className={styles.left}>&nbsp;</span>
            )}
            <span className={styles.right}>{option.label}</span>
          </div>
        )
      }}
      // The local cache causes a bug that can be reproduced by:
      // 1. Enter 3+ chars that match a card
      // 2. Enter a character that doesn't match any cards,
      //    then hit backspace AFTER the loading spinner appears but BEFORE the results appear
      // 3. The post-backspace query is now corrupted with the pre-backspace query results
      useCache={false}
    />
  )
}

export default AsyncTypeahead
