import BSToast from 'react-bootstrap/Toast'

import toastService from '@/services/toast.service'
import { useAppSelector } from '@/store/hooks'

import styles from './Toasts.module.scss'

const Toasts = () => {
  const toasts = useAppSelector((state) => state.toasts.toasts)

  return (
    <div className={styles.container}>
      {toasts.map((toast) => (
        <div className='mt-2' key={toast.key} onClick={() => toastService.hideToast(toast.key)}>
          <BSToast autohide delay={3000} onClose={() => toastService.hideToast(toast.key)} show={toast.show}>
            <BSToast.Body>{toast.text}</BSToast.Body>
          </BSToast>
        </div>
      ))}
    </div>
  )
}

export default Toasts
