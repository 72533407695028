import { CardListType, CardType, PageCardType, RecCardType } from '@/types/card'
import { formatUrl } from '@/utils/helpers'

import requestService from './request.service'

let cardCache: Record<string, CardType> = {}
const slugify = (nameOrSlug: string) => formatUrl(nameOrSlug.split('//')[0].trim())

const getCard = async (nameOrSlug: string): Promise<CardType> => {
  const slug = slugify(nameOrSlug)

  if (!slug) throw new Error('Invalid card name')

  if (cardCache[slug]) return cardCache[slug]

  const res = await requestService.get(`${process.env.NEXT_PUBLIC_EDHREC_JSON_URL}/cards/${slug}`, {}, true)
  const card = await res.json()

  // Update cache
  cardCache[slug] = card

  return card
}

const getCardMap = async (names: string[], abortable = true): Promise<Record<string, CardType>> => {
  let cards: Record<string, CardType> = {}
  const remainingCardNames: string[] = []

  for (const name of names) {
    if (!name) {
      const e = new Error()
      console.warn('Invalid card name:', name, e.stack)
      continue
    }
    if (cardCache[name]) cards[name] = cardCache[name]
    else remainingCardNames.push(name)
  }

  if (remainingCardNames) {
    const chunkSize = 100
    for (let i = 0; i < remainingCardNames.length; i += chunkSize) {
      const chunk = remainingCardNames.slice(i, i + chunkSize)
      const res = await requestService.post(
        '/api/cards/',
        {
          format: 'dict',
          names: chunk,
        },
        {},
        true,
        abortable,
      )
      cards = { ...cards, ...(await res.json()).cards }
    }
  }

  // Update cache
  cardCache = { ...cardCache, ...cards }

  return cards
}

const getPageCardMap = (
  cardMap: Record<string, CardType>,
  initCardLists: CardListType[],
): Record<string, PageCardType> => {
  // Get additional card data from cardlists
  const cards = initCardLists.reduce(
    (acc: RecCardType[], cardList: CardListType) => [...acc, ...cardList.cardviews],
    [],
  )
  const pageCardList = Object.keys(cardMap).map((key) => {
    const card = cardMap[key]
    const pageCard = cards.find((c) => c.sanitized_wo === card.url)
    return {
      ...card,
      ...pageCard,
    }
  }) as PageCardType[]

  // Add cardlist data to card map
  const pageCardMap = pageCardList.reduce(
    (acc, card) => {
      acc[card.sanitized as string] = card
      return acc
    },
    {} as Record<string, PageCardType>,
  )

  return pageCardMap
}

const updateCardCache = (newCache: Record<string, CardType>) => {
  cardCache = { ...cardCache, ...newCache }
}

const cardService = { getCard, getCardMap, getPageCardMap, updateCardCache }

export default cardService
