import Script from 'next/script'
import { Persistor } from 'redux-persist/es/types'

import Toasts from '@/components/Toasts/Toasts'
import { useLoadAds } from '@/contexts/ads'

import Footer from './Footer/Footer'
import Navbar from './Navbar/Navbar'

type Props = {
  children: React.ReactNode
  persistor: Persistor
}

const Layout = ({ children, persistor }: Props) => {
  const loadAds = useLoadAds()
  return (
    <>
      <header>
        <Navbar persistor={persistor} />
        {loadAds === true && (
          <Script
            async
            data-cfasync='false'
            data-noptimize='1'
            src='//scripts.mediavine.com/tags/edhrec.js'
            type='text/javascript'
          />
        )}
      </header>
      {children}
      <Toasts />
      <Footer />
    </>
  )
}

export default Layout
