import BSSpinner from 'react-bootstrap/Spinner'

type Props = {
  className?: string
  size?: string
}

const Spinner = ({ className, size }: Props) => (
  // @ts-ignore
  <BSSpinner animation='border' className={className} size={size || 'sm'} />
)

export default Spinner
