import classNames from 'classnames'
import { Component, useEffect, useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import BSCard from 'react-bootstrap/Card'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import ReactGA from 'react-ga4'
import { connect } from 'react-redux'

import empty from '@/assets/empty.png'
import ErrorAlert from '@/components/ErrorAlert/ErrorAlert'
import Icon from '@/components/Icon/Icon'
import Spinner from '@/components/Spinner/Spinner'
import { withLoadAds } from '@/contexts/ads'
import CardService from '@/services/card.service'
import RecsService from '@/services/recs.service'
import requestService from '@/services/request.service'
import { CLEAR_CLIPBOARD, CLOSE_MOBILE_IMAGE, SET_CLIPBOARD_STATE, UNDO } from '@/store/clipboard/actions/types'
import {
  selectAllClipboardCardNames,
  selectAllClipboardCards,
  selectClipboardPrices,
  selectExportUrls,
  selectIsEmpty,
} from '@/store/clipboard/selectors'
import { useActionless, useAppSelector } from '@/store/hooks'
import { RootState } from '@/store/reducers'
import { store } from '@/store/store'
import { PriceMapType } from '@/types/card'

import styles from './Clipboard.module.scss'
import ClipboardItems from './ClipboardItems/ClipboardItems'

const Clipboard = () => {
  const {
    commanders: commanderNames,
    error,
    isLoadingSCG,
    isOpen,
    loadedCards: cards,
    mobileImageIsOpen,
    prevStates,
    viewerImage,
  } = useAppSelector((state) => state.clipboard)
  const priceSources = useAppSelector((state) => state.user.priceSources)
  const [isLoadingRecs, setIsLoadingRecs] = useState(false)
  const cardNames = useAppSelector(selectAllClipboardCardNames)
  const [clearClipboard, closeMobileImage, setClipboardState, undo] = useActionless(
    CLEAR_CLIPBOARD,
    CLOSE_MOBILE_IMAGE,
    SET_CLIPBOARD_STATE,
    UNDO,
  )

  useEffect(() => {
    if (cardNames.length > 0) {
      CardService.getCardMap(cardNames, false).then((cards) => setClipboardState({ loadedCards: cards }))
    }
  }, [])

  const commanders = commanderNames.map((commander) => cards[commander])

  const prices = useAppSelector(selectClipboardPrices)
  const exportUrls = useAppSelector(selectExportUrls)
  const isEmpty = useAppSelector(selectIsEmpty)

  const handleRecs = async () => {
    setIsLoadingRecs(true)

    RecsService.getClipboardRecs()
      .then((recs) => {
        setClipboardState({
          loadedCards: { ...cards, ...Object.fromEntries(recs.inRecs.map((card: any) => [card.url, card])) },
          recs: recs.inRecs,
        })
      })
      .catch((err) => setClipboardState({ error: err.message }))
      .finally(() => setIsLoadingRecs(false))
  }

  const handleOutboundClick = (url: string) => {
    ReactGA.event({ action: 'Clipboard', category: 'Outbound', label: url })
  }

  const handleCopy = () => {
    const myText = Object.values(selectAllClipboardCards(store.getState()))
      .map((c) => c.name)
      .sort()
      .join('\n')
    navigator.clipboard.writeText(myText)
  }

  const handleSCG = async () => {
    setClipboardState({ isLoadingSCG: true })
    const cards = Object.values(selectAllClipboardCards(store.getState()))
      .map((c) => c.name)
      .sort()
    if (cards.length === 0) return setClipboardState({ isLoadingSCG: false })
    const resp = await requestService.post('/api/scg', { cards })
    const { url } = await resp.json()
    if (url) {
      handleOutboundClick(url)
      window.open(url, '_blank')
    }
    setClipboardState({ isLoadingSCG: false })
  }

  const cardShopButtons = {
    cardhoarder: <span />,
    cardkingdom: (
      <a
        href={exportUrls.ck}
        onClick={() => handleOutboundClick(exportUrls.ck)}
        rel='noopener noreferrer'
        target='_blank'
      >
        <Button variant='secondary'>Card Kingdom: ${prices.cardkingdom.toFixed(2)}</Button>
      </a>
    ),
    cardmarket: <span />,
    face2face: <span />,
    manapool: (
      <a
        href={exportUrls.manapool}
        onClick={() => handleOutboundClick(exportUrls.manapool)}
        rel='noopener noreferrer'
        target='_blank'
      >
        <Button variant='secondary'>Mana Pool: ${prices.manapool.toFixed(2)}</Button>
      </a>
    ),
    mtgstocks: <span />,
    scg: (
      <div className='d-sm-none d-md-block'>
        <Button onClick={() => handleSCG()} variant='secondary'>
          {isLoadingSCG ? (
            <Spinner />
          ) : (
            <div className='d-flex'>
              <span className='d-none d-sm-block d-xl-none'>SCG</span>
              <span className='d-sm-none d-xl-block'>Star City Games</span>: ${prices.scg.toFixed(2)}
            </div>
          )}
        </Button>
      </div>
    ),
    tcgl: (
      <a
        href={exportUrls.tcgl}
        onClick={() => handleOutboundClick(exportUrls.tcgl)}
        rel='noopener noreferrer'
        target='_blank'
      >
        <Button variant='secondary'>TCG Land: ${prices.tcgl.toFixed(0)} MXN</Button>
      </a>
    ),
    tcgplayer: (
      <a
        href={exportUrls.tcg}
        onClick={() => handleOutboundClick(exportUrls.tcg)}
        rel='noopener noreferrer'
        target='_blank'
      >
        <Button variant='secondary'>TCGplayer: ${prices.tcgplayer.toFixed(2)}</Button>
      </a>
    ),
  }

  return (
    <>
      <div className='d-flex h-100 w-100'>
        <BSCard className={styles.card}>
          <div className={classNames(styles.cards, styles.clipboardBorder)}>
            <ClipboardItems />
            {isLoadingRecs && <div className='w-100'>Recs</div>}
          </div>
          <div className={styles.buttons}>
            <div className={classNames(styles.buttonRow, 'd-none d-sm-flex')}>
              <Button disabled={isEmpty} onClick={clearClipboard} variant='danger'>
                <Icon icon='trash' />
              </Button>
              <Button disabled={prevStates?.length === 0} onClick={undo} variant='secondary'>
                <Icon icon='rotateLeft' /> Undo
              </Button>
            </div>
            <div className={styles.buttonRow}>
              <DropdownButton title={<span>{' Export'}</span>} variant='secondary'>
                <a className='dropdown-item' href={exportUrls.archidekt} rel='noopener noreferrer' target='_blank'>
                  Archidekt
                </a>
                <a className='dropdown-item' href={exportUrls.moxfield} rel='noopener noreferrer' target='_blank'>
                  Moxfield
                </a>
                <Dropdown.Item onClick={handleCopy}>Clipboard</Dropdown.Item>
              </DropdownButton>
              {/* @ts-ignore */}
              {priceSources.map((source: keyof PriceMapType) => (
                <span key={source}>{cardShopButtons[source]}</span>
              ))}
              <Button
                className='d-none d-sm-block'
                disabled={isLoadingRecs}
                onClick={handleRecs}
                variant={commanders.length > 0 ? 'primary' : 'secondary'}
              >
                {isLoadingRecs ? <Spinner /> : 'Recs'}
              </Button>
            </div>
            <div className={classNames(styles.mobileButtons)}>
              <Button disabled={isEmpty} onClick={clearClipboard} variant='danger'>
                <Icon icon='trash' />
              </Button>
              <Button disabled={prevStates?.length === 0} onClick={undo} variant='secondary'>
                <Icon icon='rotateLeft' /> Undo
              </Button>
              <Button
                className={styles.big}
                disabled={isLoadingRecs}
                onClick={handleRecs}
                variant={commanders.length > 0 ? 'primary' : 'secondary'}
              >
                {isLoadingRecs ? <Spinner /> : 'Recs'}
              </Button>
            </div>
            {error && (
              <Alert className='d-block d-sm-none' variant='danger'>
                {error}
              </Alert>
            )}
          </div>
        </BSCard>
        <div className={styles.imageWrapper}>
          <div className={styles.imageViewer}>
            <img
              alt='Preview'
              className={classNames(styles.image, {
                empty: viewerImage === '',
              })}
              src={viewerImage || empty.src}
            />
          </div>
        </div>
      </div>
      <div
        className={classNames(styles.mobileImageViewer, {
          [styles.open]: isOpen && mobileImageIsOpen,
        })}
        onClick={closeMobileImage}
      >
        <div className={styles.mobileImage}>
          <img alt='Preview' src={viewerImage || empty.src} />
        </div>
      </div>
    </>
  )
}

type ErrorBoundaryProps = {
  clearClipboard: () => void
  isOpen: boolean
  loadAds?: boolean
}

class ClipboardErrorBoundary extends Component<ErrorBoundaryProps> {
  state: { error: any; hasError: boolean } = { error: null, hasError: false }

  static getDerivedStateFromError(error: any) {
    return { error, hasError: true }
  }

  componentDidCatch(error: any) {
    ReactGA.event({ action: error.message, category: 'Errors', label: 'Clipboard' })
    this.props.clearClipboard()
  }

  render() {
    const stack = this.state.error?.stack || this.state.error?.message
    return (
      <div
        className={classNames(
          styles.clipboard,
          { [styles.open]: this.props.isOpen, [styles.patron]: this.props.loadAds === false },
          'edhrec-clipboard shadow-sm',
        )}
      >
        {this.state.hasError ? <ErrorAlert hideStack stack={stack} /> : <Clipboard />}
      </div>
    )
  }
}

export default connect(
  (state: RootState) => ({ isOpen: state.clipboard.isOpen }),
  (dispatch) => ({
    clearClipboard: () => dispatch({ payload: undefined as never, type: CLEAR_CLIPBOARD }),
  }),
)(withLoadAds(ClipboardErrorBoundary))
