import { UserActions } from './actions'
import * as User from './actions/types'
import { getUserInitialState } from './initialState'

const reducer = (state = getUserInitialState(), action: UserActions) => {
  switch (action.type) {
    case User.SET_USER_STATE: {
      const newState = { ...state, ...action.payload }
      return newState
    }
    case User.SET_USER_STATE_FULL:
      return action.payload
    case User.SIGN_OUT:
      return { ...getUserInitialState(), uid: null }
    default:
      return state
  }
}

export default reducer
