import { useRouter } from 'next/router'

const useDirectories = () => {
  const router = useRouter()
  const [asPath, _query] = router.asPath.split('?')
  const [_blank, dir, subpage, subsubpage, subsubsubpage, subsubsubsubpage] = asPath.split('/')
  const query = router.query

  return { dir, query, subpage, subsubpage, subsubsubpage, subsubsubsubpage }
}

export default useDirectories
