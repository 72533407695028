import { Component, ReactNode } from 'react'
import { SourceMapConsumer } from 'source-map'

import ErrorAlert from '@/components/ErrorAlert/ErrorAlert'

type Props = {
  children: ReactNode
  tag?: string
}

type State = {
  error?: Error
  hasError: boolean
  mappedStack?: string
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error) {
    return { error, hasError: true }
  }

  async componentDidCatch(error: Error, errorInfo: any) {
    const tag = this.props.tag ? `(${this.props.tag})` : ''
    console.warn(`ErrorBoundary${tag} caught:`, error, errorInfo)

    const mappedStack = await this.remapStackTrace(error)
    this.setState({ mappedStack })
  }

  async remapStackTrace(error: Error): Promise<string> {
    if (!error.stack) return error.message

    const stackLines = error.stack.split('\n')
    const remappedLines: string[] = []

    for (const line of stackLines) {
      const match = line.match(/https?:\/\/[^)]+\/_next\/static\/chunks\/([^:]+):(\d+):(\d+)/)
      if (!match) {
        remappedLines.push(line)
        continue
      }

      const [_, chunkName, lineNumStr, colNumStr] = match
      const lineNum = parseInt(lineNumStr)
      const colNum = parseInt(colNumStr)

      try {
        const mapUrl = `/_next/static/chunks/${chunkName}.map`
        const res = await fetch(mapUrl)
        const rawMap = await res.json()
        const consumer = await new SourceMapConsumer(rawMap)

        const originalPos = consumer.originalPositionFor({
          column: colNum,
          line: lineNum,
        })

        if (originalPos.source) {
          remappedLines.push(
            `  at ${originalPos.name || '(anonymous)'} (${originalPos.source}:${originalPos.line}:${originalPos.column})`,
          )
        } else {
          remappedLines.push(line)
        }

        if ('destroy' in consumer && typeof consumer.destroy === 'function') {
          consumer.destroy()
        }
      } catch (e) {
        console.warn('Failed to remap line:', line, e)
        remappedLines.push(line)
      }
    }

    return remappedLines.join('\n')
  }

  render() {
    const { error, hasError, mappedStack } = this.state

    if (hasError) {
      const stack = mappedStack || error?.stack || error?.message
      return <ErrorAlert stack={stack} />
    }

    return this.props.children
  }
}

export default ErrorBoundary
