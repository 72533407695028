import { onAuthStateChanged } from '@firebase/auth'
import { doc, onSnapshot, Unsubscribe } from 'firebase/firestore'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useLoadAds } from '@/contexts/ads'
import { auth, firestore } from '@/services/firebase.service'
import { useActionless } from '@/store/hooks'
import { SET_USER_STATE_FULL, SIGN_OUT } from '@/store/user/actions/types'
import { UserStateType } from '@/store/user/initialState'
import { sha1Encode } from '@/utils/helpers'

let unsub: Unsubscribe | undefined

const FirebaseAuth = () => {
  const dispatch = useDispatch()
  const loadAds = useLoadAds()
  const [setUserStateFull] = useActionless(SET_USER_STATE_FULL)

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // Mediavine
        // identityOptIn must run once per session
        const emailHash = await sha1Encode(user.email as string)
        window.addEventListener('mediavineIdentityReady', () => {
          if (loadAds === true) {
            // @ts-ignore
            window.$adManagementConfig.web.identityOptIn({ email: emailHash })
          }
        })

        unsub = onSnapshot(doc(firestore, 'users', user.uid), (doc) => {
          const data = doc.data() as UserStateType

          // Use subobject reference to avoid stale callback
          setUserStateFull({ ...data, uid: user.uid })
        })
      } else {
        dispatch({
          type: SIGN_OUT,
        })

        if (unsub) {
          unsub()
        }
      }
    })
  }, [])

  return null
}

export default FirebaseAuth
