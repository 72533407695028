import { store } from '@/store/store'

import { MiscStateType } from '../initialState'

import { FETCHING, FETCHING_ERROR, FETCHING_SUCCESS } from './types'

export type FetchingActionType = {
  type: typeof FETCHING
}

export type FetchingErrorActionType = {
  type: typeof FETCHING_ERROR
}

export type FetchingSuccessActionType = {
  payload: MiscStateType
  type: typeof FETCHING_SUCCESS
}

export const fetchMisc = async () => {
  const dispatch = store.dispatch

  dispatch({ type: FETCHING })
  try {
    const res = await fetch(`${process.env.NEXT_PUBLIC_EDHREC_JSON_URL}/static/misc`)
    const json = await res.json()
    const { tcgplayerKickback } = json

    // Active tcgplayer sale?
    const now = new Date()
    const tcgplayerSale =
      tcgplayerKickback && now > new Date(tcgplayerKickback.start) && now < new Date(tcgplayerKickback.end)
        ? tcgplayerKickback.percent
        : 0

    dispatch({
      payload: {
        ...json,
        tcgplayerKickback,
        tcgplayerSale,
      },
      type: FETCHING_SUCCESS,
    })
  } catch (err) {
    dispatch({ type: FETCHING_ERROR })
  }
}

export type MiscActionTypes = FetchingActionType | FetchingErrorActionType | FetchingSuccessActionType
